import { Course } from '@/models/course/classes/Course'
import { CourseStatus } from '@/models/course/enums'
import { MenuItem } from 'primevue/menuitem'
import { EducationRouteNames } from '@/router/route-names'

export function useCourseMenuItems (course: Course): MenuItem[] {
  return [
    {
      label: 'General',
      to: {
        replace: true,
        name: EducationRouteNames.COURSE_DETAIL_GENERAL
      }
    },
    {
      label: 'Clases',
      to: {
        replace: true,
        name: EducationRouteNames.COURSE_DETAIL_CLASSES
      }
    },
    {
      label: 'NPS',
      visible: course.status === CourseStatus.Finished,
      to: {
        replace: true,
        name: EducationRouteNames.COURSE_DETAIL_NPS
      }
    },
    {
      label: 'Estudiantes',
      to: {
        replace: true,
        name: EducationRouteNames.COURSE_DETAIL_TUTOR_ASSIGNMENT
      }
    },
    {
      label: 'Staff',
      to: {
        replace: true,
        name: EducationRouteNames.COURSE_DETAIL_STAFF
      }
    }
  ]
}

import { TutorStudents } from '@/models/commission-tutor-students/classes/TutorStudents'
import { StudentTypeAlert, TypeStudents } from '../../../models/enums'
import { StudentCourseModality } from '../enums'

export interface IStudentContactInfo {
  phone: string
  mail: string
}

export interface IStudentProfile {
  name: string
  lastName?: string
  avatar?: string
  country?: string
}

export interface IStudentAcademicStatus {
  assistance: number // 0 a 100
  finalGrade: number // 1 a 10
  certificate: string
}

export interface IHomeworkDeliveryStatsSummary {
  approved: number
  disapproved: number
  delivered: number
  onHold: number
}

interface IHomeworkDeliveryStatsDetail {
  approved: number
  delivered: number
  deliveryPending: number
  disapproved: number
  grade: number | null
}

export interface IHomeworkDeliveryStats {
  studentId: string
  regular: IHomeworkDeliveryStatsDetail
  complementary: IHomeworkDeliveryStatsDetail
  preFinal: IHomeworkDeliveryStatsDetail
  final: IHomeworkDeliveryStatsDetail
}

export interface ICourseStudentUser {
  personId: string
  active: boolean
  courseId: string
  type: TypeStudents
  courseModality: StudentCourseModality
}

export interface IStudentAssignment {
  teacherId: string
}

export interface IStudentUnenrollment {
  assistant: string
  reason: string
  comment: string
  returns: boolean
  reenroll: boolean
  reenrollCamadaId: string
  amountPaid: string
  date: Date
  resolved: boolean
  enable: boolean
}

export interface IStudentAlert {
  type: StudentTypeAlert
  date: Date
  teacher: string
}

interface IStudentBuilder {
  reset(): void
  buildContactInfo(contactInfo: IStudentContactInfo): IStudentBuilder
  buildUser(user: ICourseStudentUser): IStudentBuilder
  buildStudentProfile(profile: IStudentProfile): IStudentBuilder
  buildAlert(alert: IStudentAlert): IStudentBuilder
  buildAssignment(assigment: IStudentAssignment): IStudentBuilder
  buildUnenRollment(unenrollment: IStudentUnenrollment): IStudentBuilder
  buildAcademicStatus(status: IStudentAcademicStatus): IStudentBuilder
  buildChallengeStats(stats: IHomeworkDeliveryStats): IStudentBuilder
}

export interface IStudent {
  user?: ICourseStudentUser | null
  contactInfo?: IStudentContactInfo | null
  profile?: IStudentProfile | null
  alert?: IStudentAlert | null
  unenrollment?: IStudentUnenrollment | null
  assigment?: IStudentAssignment | null
  academicStatus?: IStudentAcademicStatus | null
}

export class Student implements IStudentBuilder, IStudent {
  user?: ICourseStudentUser | null
  contactInfo?: IStudentContactInfo | null
  profile?: IStudentProfile | null
  alert?: IStudentAlert | null
  unenrollment?: IStudentUnenrollment | null
  assigment?: IStudentAssignment | null
  academicStatus?: IStudentAcademicStatus | null
  challengeStats?: IHomeworkDeliveryStats | null

  constructor () {
    this.reset()
  }

  public reset (): void {
    this.user = null
    this.contactInfo = null
    this.profile = null
    this.alert = null
    this.unenrollment = null
    this.assigment = null
    this.academicStatus = null
  }

  public buildUser (user: ICourseStudentUser): Student {
    this.user = user
    return this
  }

  public buildContactInfo (contactInfo: IStudentContactInfo): Student {
    this.contactInfo = contactInfo
    return this
  }

  public buildStudentProfile (profile: IStudentProfile): Student {
    this.profile = profile
    return this
  }

  public buildAlert (alert: IStudentAlert): Student {
    this.alert = alert
    return this
  }

  public buildUnenRollment (unenrollment: IStudentUnenrollment): Student {
    this.unenrollment = unenrollment
    return this
  }

  public buildAssignment (assigment: IStudentAssignment): Student {
    this.assigment = assigment
    return this
  }

  public buildAcademicStatus (status: IStudentAcademicStatus): Student {
    this.academicStatus = status
    return this
  }

  public buildChallengeStats (stats: IHomeworkDeliveryStats): Student {
    this.challengeStats = stats
    return this
  }

  static convertToStudentsByTeacher (students: Student[], unassigned = false): Map<string, Student[]> {
    const mapper = new Map<string, Student[]>()
    for (const student of students) {
      if (student.assigment) {
        const teacherInMap = mapper.get(student.assigment.teacherId)
        if (teacherInMap) {
          teacherInMap.push(student)
          mapper.set(student.assigment.teacherId, teacherInMap)
        } else {
          mapper.set(student.assigment.teacherId, [student])
        }
      } else {
        if (unassigned && !student.unenrollment && student.user?.active) {
          const unassignedInMap = mapper.get('unassigned')
          if (unassignedInMap) {
            unassignedInMap.push(student)
            mapper.set('unassigned', unassignedInMap)
          } else {
            mapper.set('unassigned', [student])
          }
        }
      }
    }
    return mapper
  }

  static convertToTutorStudentsMap (students: Student[], unassigned = false, tutorStudents: TutorStudents[]): Map<string, Student[]> {
    const mapper = new Map<string, Student[]>()
    for (const student of students) {
      const existingTutosStudent = tutorStudents.find(tutorStudent => tutorStudent.students.find((tst: { id: string | undefined }) => tst.id === student.user?.personId))
      if (existingTutosStudent) {
        const teacherInMap = mapper.get(existingTutosStudent.tutor.id)
        if (teacherInMap) {
          teacherInMap.push(student)
          mapper.set(existingTutosStudent.tutor.id, teacherInMap)
        } else {
          mapper.set(existingTutosStudent.tutor.id, [student])
        }
      } else {
        if (unassigned && !student.unenrollment && student.user?.active) {
          const unassignedInMap = mapper.get('unassigned')
          if (unassignedInMap) {
            unassignedInMap.push(student)
            mapper.set('unassigned', unassignedInMap)
          } else {
            mapper.set('unassigned', [student])
          }
        }
      }
    }
    return mapper
  }
}

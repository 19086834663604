import {
  ICourseStudentUser,
  IStudent,
  IStudentAcademicStatus,
  IStudentAlert,
  IStudentAssignment,
  IStudentContactInfo,
  IStudentProfile,
  IStudentUnenrollment,
  Student
} from '@/models/course/builder/student.builder'
import { Api } from './api'
import { CRUD } from './crud'

class StudentApi implements CRUD<Student> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async findAll(courseId: string): Promise<Student[]> {
    const dtoStudents = await Api.get<IStudent[]>(
      `${this.baseURL}/v1/courses/${courseId}/student`,
      {
        format: 'Minimal'
      }
    )
    return dtoStudents.map((s) => {
      const student = new Student()
        .buildUser(s.user as ICourseStudentUser)
        .buildStudentProfile(s.profile as IStudentProfile)
        .buildUnenRollment(s.unenrollment as IStudentUnenrollment)
        .buildAssignment(s.assigment as IStudentAssignment)
        .buildAlert(s.alert as IStudentAlert)
        .buildAcademicStatus(s.academicStatus as IStudentAcademicStatus)
        .buildContactInfo(s.contactInfo as IStudentContactInfo)
      return student
    })
  }

  public async pushStudentInTutoring(
    commissionId: string,
    students: string[],
    tutor: string
  ): Promise<void> {
    return await Api.put<void>(
      `${this.baseURL}/v1/course/${commissionId}/push-student-tutoring`,
      { students, tutor }
    )
  }

  public async deleteStudentsInTutoring(
    commissionId: string,
    tutorId: string,
    toUnassign: string[]
  ): Promise<void> {
    return await Api.del<void>(
      `${this.baseURL}/v1/course/${commissionId}/delete-student-tutoring`,
      { tutor: tutorId, students: toUnassign }
    )
  }

  public async multipleDeleteStudentsInTutoring(
    commissionId: string,
    toUnassign: Map<string, Student[]>
  ): Promise<void[]> {
    const toDeletePromise = Promise.all(
      Array.from(toUnassign.keys()).map((idTutor) => {
        const studentsId = toUnassign
          .get(idTutor)
          ?.map((student) => student.user?.personId)
        return this.deleteStudentsInTutoring(
          commissionId,
          idTutor,
          studentsId as string[]
        )
      })
    )
    return await toDeletePromise
  }
}

export const StudentAPI = new StudentApi()

import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { SyllabusWithExpirateDate } from '@/models/academic-definitions/interfaces/composition/SyllabusWithExpirateDate'
import { Api } from '../api'
import { CRUD } from '../crud'

class SyllabusApi implements CRUD<Syllabus> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async findById(id: string): Promise<Syllabus> {
    return await Api.get(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/${id}`
    )
  }

  public async create(syllabusDTO: Partial<Syllabus>): Promise<Syllabus> {
    return await Api.post(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/create`,
      syllabusDTO
    )
  }

  public async delete(id: string): Promise<Syllabus> {
    return await Api.del(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/delete/${id}`
    )
  }

  public async findLastVersions(coursePlanId: string): Promise<Syllabus[]> {
    const lastVersionsObject = await Api.get<Record<string, Syllabus>>(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/lastVersion/${coursePlanId}`
    )
    return Object.values(lastVersionsObject)
  }

  public async findActiveVersions(
    coursePlanId: string
  ): Promise<SyllabusWithExpirateDate[]> {
    return await Api.get(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/active/${coursePlanId}`
    )
  }

  public async findArchivedVersions(coursePlanId: string): Promise<Syllabus[]> {
    return await Api.get(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/archived/${coursePlanId}`
    )
  }

  public async togglePublished(syllabusId: string): Promise<Syllabus> {
    return await Api.patch(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/published/${syllabusId}`,
      null
    )
  }
}

export const SyllabusAPI = new SyllabusApi()


import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useCourseMenuItems } from './tab-menu-items'
import { MenuItem } from 'primevue/menuitem'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import ChTag from '@/components/commons/boxes/ChTag.vue'
import { Course } from '@/models/course/classes/Course'
import { CourseAPI } from '@/api/course.api'
import {
  CourseFeedbackSummaryAPI,
  CourseFeedbackAPI
} from '@/api/feedbacks.api'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { CommonRouteNames } from '@/router/route-names'
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { SyllabusAPI } from '@/api/academic-definitions/syllabus.api'
import { Student } from '@/models/course/builder/student.builder'
import { StudentAPI } from '@/api/students.api'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { StaffMemberAPI } from '@/api/staffMember.api'
import { useConfirm } from 'primevue/useconfirm'

export default defineComponent({
  components: {
    ModuleHeader,
    LoadingScreen,
    ChTag
  },
  setup () {
    const loading = ref<boolean>(false)
    const {
      params: { courseId }
    } = useRoute()
    const menuItems = ref<MenuItem[]>([])
    const course = ref<Course>(Object.create(null))
    const students = ref<Student[]>([])
    const summaries = ref<ClassFeedbackSummary[]>([])
    const staff = ref<StaffMember[]>([])
    const isLoading = ref(false)
    const staffIsLoading = ref(false)
    const router = useRouter()
    const errorRef = ref<null | unknown>(null)
    const syllabus = ref<Syllabus>()
    const confirm = useConfirm()
    const confirmReprocessFeedback = () =>
      new Promise<boolean>(resolve =>
        confirm.require({
          group: 'global',
          message: 'El proceso de reprocesar puede tardar unos minutos.',
          header: 'Importante',
          acceptLabel: 'Aceptar ',
          rejectLabel: 'Cancelar',
          accept: () => resolve(true),
          reject: () => resolve(false)
        })
      )
    const refreshValues = async (courseId: string): Promise<void> => {
      const idInterval = await setInterval(async () => {
        try {
          const camadaStatus = await CourseFeedbackSummaryAPI.getCamadaStatus(
            courseId
          )
          if (!camadaStatus.isPending) {
            isLoading.value = true
            summaries.value = await CourseFeedbackSummaryAPI.findAll(courseId)
            isLoading.value = false
            loading.value = false
            clearInterval(idInterval)
          }
        } catch (error) {
          console.error(error)
        }
      }, 25000)
    }
    const reprocessFeedback = async (courseId: string): Promise<void> => {
      try {
        if (!(await confirmReprocessFeedback())) return
        loading.value = true
        const camadaStatus = await CourseFeedbackSummaryAPI.getCamadaStatus(
          courseId
        )
        if (!camadaStatus.isPending) {
          await CourseFeedbackAPI.reprocessFeedback(courseId)
        }
        refreshValues(courseId)
      } catch (e) {
        console.error(e)
      }
    }

    onBeforeMount(() => {
      isLoading.value = true
    })

    onMounted(async () => {
      try {
        isLoading.value = true
        const coursePromise = CourseAPI.findById(courseId as string)
        const studentsPromise = StudentAPI.findAll(courseId as string)
        course.value = await coursePromise
        students.value = await studentsPromise
        summaries.value = await CourseFeedbackSummaryAPI.findAll(
          courseId.toString()
        )
        loading.value = true
        const camadaStatus = await CourseFeedbackSummaryAPI.getCamadaStatus(
          courseId.toString()
        )
        if (!camadaStatus.isPending) {
          loading.value = false
        } else {
          refreshValues(courseId.toString())
        }
        const syllabusPromise = SyllabusAPI.findById(
          course.value.program.syllabusId as string
        )
        syllabus.value = await syllabusPromise
        menuItems.value = useCourseMenuItems(course.value)
      } catch (error) {
        errorRef.value = error
        router.push({
          name: CommonRouteNames.ERROR_SCREEN,
          params: {
            error: JSON.stringify(error)
          }
        })
      } finally {
        isLoading.value = false
      }
    })
    return {
      course,
      students,
      summaries,
      menuItems,
      syllabus,
      Course,
      isLoading,
      errorRef,
      staff,
      loading,
      onLoadStaff: async () => {
        try {
          staffIsLoading.value = true
          staff.value = await StaffMemberAPI.findAll(course.value._id)
        } catch (error) {
          errorRef.value = error
          router.push({
            name: CommonRouteNames.ERROR_SCREEN,
            params: {
              error: JSON.stringify(error)
            }
          })
        } finally {
          staffIsLoading.value = false
        }
      },
      staffIsLoading,
      reprocessFeedback
    }
  }
})
